import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";
import groupBy from "lodash/groupBy";
import { format, fromUnixTime } from "date-fns";
import { de as deLocale } from "date-fns/locale";

import { render } from "./available-courses.html";
import { type SearchResultItem } from "../models/search-result-item";
import { CourseResultAgendaItem } from '../filter/course-result-agenda-item';

@Component({
	render,
	components: {
		CourseResultAgendaItem
	},
})
export class AvailableCourses extends Vue {
	courses: SearchResultItem[] = [];

	@Prop() dataSourceSelector: string;

	get coursesGroupedByMonth() {
		return groupBy(
			this.courses.map((r, i) => { return { index: i, value: r }; }),
			x => x.value.beginTimestamp ? format(fromUnixTime(x.value.beginTimestamp), "MMMM yyyy", { locale: deLocale }) : "Beginn nach Stundenplan"
		);
	}

	mounted() {
		let sourceElement = document.querySelector(this.dataSourceSelector);
		if (sourceElement != null && sourceElement.textContent != null) {
			this.courses = JSON.parse(sourceElement.textContent);
		}
	}
}
