import { Vue, Component, Prop, Watch, Inject, Provide } from "@wagich/vue-facing-decorator-metadata";
import { create, enforce, omitWhen, only, test, warn, type SuiteResult } from "vest";
import "vest/enforce/email";

import { State, Gender } from "../utilities";
import { render } from "./download-order.html";

interface DownloadOrderRequest {
	firstname: string;
	lastname: string;
	email: string;
	phone: string;
	gender: Gender | null;
	signupForNewsletter: boolean;
	token: string;
	pageTitle: string;
}

interface DownloadOrderResponse {
	title: string;
	downloadUrl: string;
}

const validator = create((data: DownloadOrderRequest, fieldName: string | undefined = undefined) => {
	only(fieldName);

	test("gender", "Bitte wähle eine Anrede aus", () => {
		enforce(data.gender).inside([Gender.Male, Gender.Female]);
	});

	test("firstname", "Bitte gib deinen Vornamen ein", () => {
		enforce(data.firstname).isNotEmpty();
	});

	test("lastname", "Bitte gib deinen Nachnamen ein", () => {
		enforce(data.lastname).isNotEmpty();
	});

	test("email", () => {
		enforce(data.email)
			.message("Bitte gib deine E-Mail-Adresse ein")
			.isNotEmpty()
			.message("Bitte gib eine gültige E-Mail-Adresse ein")
			.isEmail();
	});
});

@Component({
	render,
})
export class DownloadOrder extends Vue {
	validations: SuiteResult<string, string> = validator.get();
	state: State = State.Initial;
	isOpen: boolean = false;

	@Prop() label: string;
	@Prop() token: string;

	response: DownloadOrderResponse | null = null;

	data: DownloadOrderRequest = {
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		gender: null,
		signupForNewsletter: false,
		token: "",
		pageTitle: document.title,
	}

	get maleLabel(): string {
		return this.data.firstname ? `Lieber ${this.data.firstname}` : `Lieber Max`;
	}
	get femaleLabel(): string {
		return this.data.firstname ? `Liebe ${this.data.firstname}` : `Liebe Maxine`;
	}

	async validate(fieldName?: string) {
		await this.$nextTick();
		this.validations = validator(this.data, fieldName);
	}

	async send() {
		await this.validate();
		if (!this.validations.isValid()) {
			return;
		}

		this.state = State.Loading;

		try {
			let responseMessage = await fetch("/api/download-order", {
				body: JSON.stringify({
					...this.data,
					token: this.token,
				}),
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json"
				}
			});

			if (responseMessage.status < 300) {
				this.response = await responseMessage.json();
				this.state = State.Success;

				this.clear();
			} else {
				this.state = State.Error;
			}

			try {
				gtag("event", "generate_lead", { event_category: "Download-Formular" });
			} catch (ex) { }
			try {
				_paq.push(["trackEvent", "Formulare", "Download angefordert"]);
			} catch { }
		} catch (ex) {
			this.state = State.Error;
		}
	}

	closeAndReset() {
		this.isOpen = false;
		this.state = State.Initial;
		this.response = null;
		this.clear();
	}

	clear() {
		this.data.firstname = "";
		this.data.lastname = "";
		this.data.email = "";
		this.data.phone = "";
		this.data.gender = null;
		this.data.signupForNewsletter = false;
	}
}
