<v-dialog max-width="48em" v-model="isOpen">
	<template v-slot:activator="{ props }">
		<v-btn class="modal-trigger__button" color="accent" v-bind="props">{{ label }}</v-btn>
	</template>
	<v-card class="download-order-form bg-grey-lighten-4 pa-2 mb-4">
		<v-card-text>
			<v-row>
				<v-col>
					<h3>{{label}}</h3>
				</v-col>
				<v-col cols="auto">
					<v-btn :icon="$icons.mdiClose" variant="text" @click="closeAndReset"></v-btn>
				</v-col>
			</v-row>

			<template v-if="state === 'initial' || state === 'loading'">
				<v-container class="pa-0 pt-4 clearfix">
					<v-row>
						<v-col cols="6">
							<v-text-field hide-details="auto" label="Vorname" placeholder="Maxine" autocomplete="given-name" v-model="data.firstname" :error-messages="validations.getErrors('firstname')" @update:modelValue="validate('firstname')" />
						</v-col>
						<v-col cols="6">
							<v-text-field hide-details="auto" label="Nachname" placeholder="Muster" autocomplete="family-name" v-model="data.lastname" :error-messages="validations.getErrors('lastname')" @update:modelValue="validate('lastname')" />
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="6">
							<v-text-field hide-details="auto" label="E-Mail" placeholder="maxinemuster@example.com" autocomplete="email" v-model="data.email" :error-messages="validations.getErrors('email')" @update:modelValue="validate('email')" />
						</v-col>
						<v-col cols="6">
							<v-text-field hide-details="auto" label="Telefon (optional)" placeholder="079 123 45 67" v-model="data.phone" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="auto">
							<v-radio-group class="mt-0" row v-model="data.gender" :error-messages="validations.getErrors('gender')" @update:modelValue="validate('gender')">
								<v-radio :label="femaleLabel" value="female" />
								<v-radio :label="maleLabel" value="male" />
							</v-radio-group>
						</v-col>
						<v-col></v-col>
						<v-col cols="auto">
							<v-checkbox label="Newsletter abonnieren" class="mt-1" hide-details v-model="data.signupForNewsletter" />
						</v-col>
					</v-row>
				</v-container>
				<v-btn color="accent" :loading="state === 'loading'" @click="send">{{label}}</v-btn>
			</template>

			<v-alert v-if="state === 'success'">
				<p>Vielen Dank für dein Interesse!</p>
				<p>Du kannst das Dokument unter diesem Link herunterladen: <a :href="response.downloadUrl" target="_blank">{{response.title}}</a></p>
			</v-alert>
		</v-card-text>

		<v-snackbar top center color="error" :modelValue="state === 'error'">
			<p>Das Formular konnte aus technischen Gründen nicht gesendet werden.</p>
			<p>Bitte kontaktiere uns per Telefon unter 033 225 44 22 oder schreibe uns eine Mail an <a href='mailto:mail@bodyfeet.ch'>mail@bodyfeet.ch</a>.</p>
			<p>Vielen Dank für dein Verständnis.</p>
		</v-snackbar>
	</v-card>
</v-dialog>
